import React from 'react'
import { Header, Image, Modal } from 'semantic-ui-react'
import { WhatDoWeDo } from 'windtrails-components';

const aboutUsData = {
  whatDoWeDo: {
    image: '', //require('../../static/images/home-img-1.png'),
    header: 'What Do We Do',
    headerDetails: 'What do we do and what tours we organize?',
    details: () => <WhatDoWeDo />,
  }
}

const AboutUsDetail = props => {
  const {trigger, aboutUsSelection} = props
  const detailsData = aboutUsData[aboutUsSelection]
  const {image, header, details, headerDetails} = detailsData

  return (
    <Modal
      trigger={trigger}
      size='small'
      closeIcon
    >
      <Modal.Header as='h2'>{header}</Modal.Header>
      <Modal.Content>
        <Header as='h3'>{headerDetails}</Header>
        {image && (
          <Image
            className='flex-row-align-center'
            wrapped
            size='medium'
            src={image}
            centered
          />
        )}
        {details()}
      </Modal.Content>
    </Modal>
  )
}

export default AboutUsDetail
