import React from 'react'
import { Modal, Image } from 'semantic-ui-react'
import {cloudinaryConfig} from './constants'
import cloudinary from 'cloudinary-core';
const {cloudName, publicIdRelativeUrl} = cloudinaryConfig

const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: cloudName});


const ImageDisplayModal = ({isModalOpen, currentImgUrl, toggleOpen}) => {
  const imgUrl = cloudinaryCore.url(`${publicIdRelativeUrl}/${currentImgUrl}`)
  return (
  <Modal
    size='small'
    closeIcon
    onClose={() => toggleOpen(false)}
    open={isModalOpen}>
    <Modal.Content>
      <Modal.Description>
          <Image size='huge' src={imgUrl} wrapped centered bordered />
      </Modal.Description>
    </Modal.Content>
  </Modal>
)
}

export default ImageDisplayModal
