// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA3o80CLe7zTSQIdF_A-f2r06ABRSIlT0s",
  authDomain: "windtrailsindiaweb.firebaseapp.com",
  projectId: "windtrailsindiaweb",
  storageBucket: "windtrailsindiaweb.appspot.com",
  messagingSenderId: "330640335852",
  appId: "1:330640335852:web:fc8c67aad4d79ca24716c9",
  measurementId: "G-5TXK95NJP3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
  
// Sign in user anonymously, catch error if any
signInAnonymously(getAuth(app)).catch(function(error) {
    console.log(error);
  });

const db = getFirestore(app)
const analytics = getAnalytics(app);

// API URLS
const BIRDING_LIST = 'birdingToursList';
const WILD_TOURS_LIST = 'wildToursList';
const GALLERY_IMAGES = 'galleryImages';
const UPCOMING_TOURS = 'upcomingWildTours';
const GENERAL_FAQS_REF = 'generalFaqs';
const BIRDING_FAQS_REF = 'birdingFaqs';
const SAFARI_FAQS_REF = 'safariFaqs';
const BANNER_IMAGES_REF = 'homePageImages';
const OTHER_MAJOR_EVENTS_REF = 'otherMajorEvents';

export {
  db,
  analytics,
  BIRDING_LIST,
  GALLERY_IMAGES,
  UPCOMING_TOURS,
  WILD_TOURS_LIST,
  GENERAL_FAQS_REF,
  BIRDING_FAQS_REF,
  SAFARI_FAQS_REF,
  BANNER_IMAGES_REF,
  OTHER_MAJOR_EVENTS_REF
}