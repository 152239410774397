import React from 'react'
import {Segment, Container, Grid, List, Header, Image} from 'semantic-ui-react'
import WildToursList from './static/data/WildToursList'
import cloudinary from 'cloudinary-core';
import {EXTERNAL_LINKS, cloudinaryConfig, ALL_ROUTES_MPA} from './constants'

const {fb_page, insta_page, blog} = EXTERNAL_LINKS
const {EVENT_DETAILS, CONTACT_US, ABOUT_US} = ALL_ROUTES_MPA
const {cloudName, publicIdRelativeUrl} = cloudinaryConfig
const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: cloudName});

const WindTrailsFooter = () => {
  return (
    <Segment inverted vertical className='padding-t-b-only-1em'>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Image
                className='cursor-pointer'
                src={cloudinaryCore.url(`${publicIdRelativeUrl}/Logo_1`)}
                size='tiny'
                centered bordered rounded
                href='/'
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About' className='primary-font' />
              <List size='tiny' link inverted className='footer-actions-container'>
                <List.Item className='cursor-pointer' href={ABOUT_US}>
                  About Us
                </List.Item>
                <List.Item className='cursor-pointer' href={CONTACT_US}>
                  Contact Us
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Highlights' className='primary-font' />
              <List size='tiny' link inverted className='footer-actions-container'>
              {WildToursList.slice(0, 5).map(({name, displayName}) => {
                return (
                  <List.Item
                    key={name}
                    name={name}
                    className='cursor-pointer'
                    href={`${EVENT_DETAILS}?eventid=${name}`}
                    target='_blank'
                  >
                    {displayName}
                  </List.Item>
                )
              })}
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted className='primary-font'>
                WindTrails
              </Header>
              <p className='footer-link'>
                Offering the best experience of wild India.
              </p>
              <a href={fb_page} target="_blank"><i className="primary-font fab fa-instagram padding-1em font-size-1-5"></i></a>
              <a href={insta_page} target="_blank"><i className="primary-font fab fa-facebook-square padding-1em font-size-1-5"></i></a>
              <a href={blog} target="_blank"><i className="primary-font fab fa-wordpress padding-1em font-size-1-5"></i></a>
              <p className='footer-link'>
                Images Copyrights &#169; 2022 WindTrailsIndia
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  )
}

export default WindTrailsFooter
