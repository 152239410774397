export const WildToursListDisplayList = [
  {
    name: 'kabini',
    displayName: 'Nagarhole',
    price: 8500,
    location: 'https://goo.gl/maps/SS7mDp77F8kkF1cZ7',
    highlights: '2 wild safaris, 2 Days/1 Night',
    state: 'KA',
    imgUrl: 'kabini/kabini_card'
  },
  {
    name: 'tadoba',
    displayName: 'Tadoba National Park',
    price: 16000,
    location: 'https://goo.gl/maps/VemSjNFBSs1u3hi46',
    state: 'MH',
    highlights: '5 wild safaris, 3 Days/2 Nights',
    imgUrl: 'tadoba/tadoba_card'
  },
  {
    name: 'kanha',
    displayName: 'Kanha Tiger Reserve',
    price: 17000,
    location: 'https://goo.gl/maps/FjoHxZjQSPbeWxNv5',
    highlights: '4 wild safaris, 3 Days/2 Nights',
    state: 'MP',
    imgUrl: 'kanha-15'
  },
  {
    name: 'pench',
    displayName: 'Pench',
    price: 17000,
    location: 'https://goo.gl/maps/8SZDcg1eRXuvosgcA',
    highlights: '5 wild safaris, 3 Days/2 Nights',
    state: 'MH',
    imgUrl: 'pench/pench_card'
  },
  {
    name: 'kaziranga',
    displayName: 'Kaziranga National Park',
    price: 17000,
    location: 'https://goo.gl/maps/ZBvsYvjSF55kLnTG7',
    highlights: '4 wild safaris',
    state: 'AS',
    imgUrl: 'kaziranga/kaziranga_card'
  },
  // {
  //   name: 'bandhavgarh',
  //   displayName: 'Bandhavgarh National Park',
  //   price: 17000,
  //   location: 'https://goo.gl/maps/hr5oiDytKu6o3NvaA',
  //   highlights: '4 wild safaris',
  //   state: 'MP',
  //   imgUrl: 'kanha-15'
  // },
  // {
  //   name: 'bandhavgarh',
  //   displayName: 'Bandhavgarh National Park',
  //   price: 17000,
  //   location: 'https://goo.gl/maps/hr5oiDytKu6o3NvaA',
  //   highlights: '4 wild safaris',
  //   state: 'MP',
  //   imgUrl: 'kanha-15'
  // },
]

export default WildToursListDisplayList
