import React, { useEffect, useState } from 'react'
import { Header, Dimmer, Loader } from 'semantic-ui-react'
import { Carousel } from 'react-responsive-carousel'
import { WindtrailsWebappFirebaseConfigs } from 'windtrails-components'
import EventCard from './EventCard'
import MediaQuery from 'react-responsive'
import {collection, getDocs } from "firebase/firestore"

const { db } = WindtrailsWebappFirebaseConfigs;

const ResponsiveWrapper = ({tourDetails, index}) => {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={1224} as={React.Fragment}>
          <EventCard
              key={`${index}-${tourDetails.name}`}
              additionalClasses={'slider-event-card width-full'}
              {...tourDetails}
          />
        </MediaQuery>
        <MediaQuery minWidth={1224} as={React.Fragment}>
          <EventCard
              key={`${index}-${tourDetails.name}`}
              additionalClasses={'slider-event-card margin-1em'}
              {...tourDetails}
          />
        </MediaQuery>
      </React.Fragment>
    )
  }

const ResponsiveCarouselDisplay = ({device}) => {
  const [wildTours, setAllWildTours] = useState([])
  useEffect(() => {
    const getWildTours = async () => {
      const upcomingWildtoursRef = await getDocs(collection(db, 'upcomingWildtours'))
      let allWildTours = []
      upcomingWildtoursRef.forEach((doc) => {
        allWildTours.push(doc.data());
      });
      setAllWildTours(allWildTours);
    }
    getWildTours();
  }, [])

  const centerModeProps = device === 'large-device' ? {
    centerMode: true,
    centerSlidePercentage: '35'
  } : {}
  
  return (
    <div className='width-full min-height-300'>
      <Header as='h1' textAlign='center' className='primary-font' block>
        Upcoming Tours And Expeditions
        <Header.Subheader>
          <p className='secondary-font'>Birding, Trekking and Safaris</p>
        </Header.Subheader>
      </Header>
      {
        wildTours.length ? 
        <Carousel
          autoPlay={false}
          showThumbs={false}
          infiniteLoop
          {...centerModeProps}
          className={`upcoming-events-slider rounded-corners background-transparent width-full ${device}`}>
          {
              
              wildTours.map((tourDetails, index) => {
              return (        
                <ResponsiveWrapper
                  key={`${tourDetails.name}-${tourDetails.toDate}-${index}`}
                  tourDetails={tourDetails}
                  index={index}
                />
              )
              })
          }
        </Carousel> : (
          <Dimmer active>
            <Loader indeterminate size='big'>Loading upcoming events</Loader>
        </Dimmer>)
      }

    </div>
  )
}

const UpcomingEventsSlider = () => {
  return (
    <React.Fragment>
      <MediaQuery maxWidth={1224} as={React.Fragment}>
        <ResponsiveCarouselDisplay device='mobile-device' />
      </MediaQuery>
      <MediaQuery minWidth={1224} as={React.Fragment}>
        <ResponsiveCarouselDisplay device='large-device' />
      </MediaQuery>
    </React.Fragment>
  )
}

export default UpcomingEventsSlider
