export const ALL_ROUTES = {
  CONTACT_US: '/windTrailsContact',
  ABOUT_US: '/windTrailsAbout',
  GALLERY: '/windTrailsGallery',
  GENERAL_FAQS: '/windTrailsGeneralFaqs',
  LISTING: '/windTrailsEventsList',
  EVENT_DETAILS: '/windTrailsEventDetails',
}

export const ALL_ROUTES_MPA = {
  CONTACT_US: '/contact',
  ABOUT_US: '/about',
  GALLERY: '/gallery',
  GENERAL_FAQS: '/generalFaqs',
  LISTING: '/eventslist',
  EVENT_DETAILS: '/eventdetails',
}

export const cloudinaryConfig = {
  'cloudName': 'windtrails-in',
  'publicIdRelativeUrl': 'windtrailsindia'
}

export const HEADER_LABELS = {
  BirdingToursList: 'Birding Tours',
  WildToursList: 'Wild Tours',
  TrekkingList: 'Trekking',
  EducationalToursList: 'Educational Tours'
}

export const TOURS_ICONS = {
  BirdingToursList: 'fas fa-feather-alt',
  WildToursList: 'fas fa-paw',
  TrekkingList: 'fas fa-hiking',
}

export const TEMP_COMING_SOON = {
  EducationalToursList: 'coming_soon_edu',
  TrekkingList: 'launching_soon'
}

export const EXTERNAL_LINKS = {
  fb_page: 'https://www.facebook.com/windtrailsindia/',
  insta_page: 'https://www.instagram.com/windtrailsindia/',
  blog: 'https://trippinzindagi.wordpress.com/'
}