import React, { useEffect, useState } from 'react'
import { Grid, Header, Dimmer, Loader } from 'semantic-ui-react'
import { Image, CloudinaryContext, Transformation } from 'cloudinary-react';
import { WindtrailsConstants, WindtrailsUtils, WindtrailsWebappFirebaseConfigs } from 'windtrails-components'
import { collection, getDocs } from "firebase/firestore"

const { onEventSelect } = WindtrailsUtils;
const { db, OTHER_MAJOR_EVENTS_REF } = WindtrailsWebappFirebaseConfigs;
const { cloudinaryConfig } = WindtrailsConstants;
const { cloudName, publicIdRelativeUrl } = cloudinaryConfig;

const OtherMajorEvents = () => {
  const [otherEvents, setOtherEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getOtherEvents = async () => {
      const othrEventsRef = await getDocs(collection(db, OTHER_MAJOR_EVENTS_REF));
      setLoading(false);
      let allOtherEvents = []
      othrEventsRef.forEach((doc) => {
        allOtherEvents.push(doc.data());
      });
      setOtherEvents(allOtherEvents);
    }
    getOtherEvents();
  }, []);

  return (
      <div className='box bottom-main rounded-corners'>
        <Grid className='info float-container'>
          <Grid.Row className='bottom-title'>
            <Grid.Column width={16}>
              <Header as='h2' className='primary-font'>
                Exploring The Nature
                <Header.Subheader>
                  <p>Birding, Trekking and Safaris</p>
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered className='other-major-events-thumbnails-view'>
            {
              !loading ? 
              otherEvents.map((otherEvent, index) => {
                const {name, displayName, isSelectable, shortInfo, imgUrl} = otherEvent
                const clickOtherEvent = isSelectable ? () => onEventSelect(name) : null
                return (
                  <Grid.Column
                    key={`${name}-${imgUrl}-${index}`}
                    onClick={clickOtherEvent}
                    mobile={16} computer={5} largeScreen={5}
                    className=''
                  >
                    <div className='bottom-img other-event-card'>
                      <CloudinaryContext cloudName={cloudName}>
                        <Image publicId={`${publicIdRelativeUrl}/${imgUrl}`} width="100%" height="210px">
                          <Transformation fetch_format="auto" />
                          <Transformation quality="auto" />
                        </Image>
                      </CloudinaryContext>
                      <p className='first'>{displayName}</p>
                    </div>
                  </Grid.Column>
                )
              }) : (
                <Dimmer active>
                  <Loader indeterminate size='big'>Loading other events</Loader>
                </Dimmer>
              )
            }
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} className='location-main'>
              <p className='bottom-desc'>
                Our aim is to get more and more people close to
                nature, for them being able to appreciate the beauty
                of it. We offer the best suitable dates and places,
                based on the weather conditions, animal behaviour and
                bird migrations, to ehance the experince to the max.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} className='location-main'>
              <hr className='hr-class' />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    
  )
}

export default OtherMajorEvents;
