import React from 'react'
import {Menu, Dropdown, Image} from 'semantic-ui-react'
import cloudinary from 'cloudinary-core'
import {ALL_ROUTES_MPA} from './constants'


const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: 'windtrails-in'});
const {CONTACT_US, ABOUT_US, GALLERY, GENERAL_FAQS, LISTING, EVENT_DETAILS} = ALL_ROUTES_MPA

const DesktopHeader = props => {
  const {fixed, WildToursList, BirdingToursList, TrekkingList} = props
  return (
    <Menu
      fixed={fixed ? 'top' : null}
      inverted={fixed}
      pointing={!fixed}
      secondary={!fixed}
      size='large'
      className='default-header'>
        <Menu.Item className='primary-font' as='a' href='/'>
          <Image src={cloudinaryCore.url(`windtrailsindia/Logo_1`)} size='mini' centered bordered rounded style={{ marginRight: '1.5em' }} />
          WindTrails
        </Menu.Item>
        <Menu.Item as='a' className='primary-font' href='/'>
          Home
        </Menu.Item>
        <Dropdown className='primary-font' simple item text='Wild Tours'>
          <Dropdown.Menu>
            {WildToursList.map(({name, displayName}) => {
              return (
                <Dropdown.Item
                  href={`${EVENT_DETAILS}?eventid=${name}`}
                  target='_blank'
                  key={name}
                  icon={<i className="fas fa-paw margin-r-5"></i>}
                  text={displayName}
                  name={name}>
                </Dropdown.Item>
              )
            })}
            <Dropdown.Item 
              name='WildToursList' 
              href={`${LISTING}?name=WildToursList`}
              target='_blank'
            >
              <i className="fas fa-list margin-r-5"></i>
              View all tours
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown
          name='BirdingToursList'
          className='primary-font'
          text='Birding Tours'
          simple
          item
        >
          <Dropdown.Menu>
            {BirdingToursList.map(({name, displayName}) => {
              return (
                <Dropdown.Item 
                  href={`${EVENT_DETAILS}?eventid=${name}`}
                  target='_blank'
                  icon={<i className="fas fa-feather-alt margin-r-5"></i>}
                  key={name}
                  text={displayName}
                  name={name}>
                </Dropdown.Item>
              )
            })}
            <Dropdown.Item 
              name='BirdingToursList' 
              href={`${LISTING}?name=BirdingToursList`}
              target='_blank'
            >
              <i className="fas fa-list margin-r-5"></i>
              View all tours
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <Menu.Item 
          className='primary-font' 
          as='a' 
          href={`${LISTING}?name=EducationalToursList`}
        >
          Educational Tours
        </Menu.Item> */}
        {/*<Dropdown className='primary-font' simple item text='Trekking Trails'>
          <Dropdown.Menu>
            {TrekkingList.map(({name, displayName}) => {
              return (
                <Dropdown.Item
                  onClick={(e, {name}) =>
                    handleIDLinkRoute({
                      routeLink: EVENT_DETAILS,
                      id: name,
                    })
                  }
                  key={name}
                  name={name}>
                  {displayName}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown> */}
        {/* <Menu.Item className='primary-font' as='a' href={`${LISTING}?name=TrekkingList`}>
          Trekking Trails
        </Menu.Item> */}
        <Menu.Item className='primary-font' as='a' href={GALLERY}>
          Gallery
        </Menu.Item>
        <Menu.Item className='primary-font' as='a' href={ABOUT_US}>
          About Us
        </Menu.Item>
        <Menu.Item className='primary-font' as='a' href={CONTACT_US}>
          Contact Us
        </Menu.Item>
        {/* <Menu.Item>
          <NavLink className='primary-font' to={GENERAL_FAQS}>Faqs</NavLink>
        </Menu.Item>*/}
    </Menu>
  )
}

export default DesktopHeader
