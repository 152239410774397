import React, {useState} from 'react'
import {
  Menu,
  Segment,
  Sidebar,
  Icon,
  Image
} from 'semantic-ui-react'
import cloudinary from 'cloudinary-core'
import MobileHeader from './MobileHeader'
import {cloudinaryConfig} from './constants'
import MediaQuery from 'react-responsive'

const {cloudName, publicIdRelativeUrl} = cloudinaryConfig
const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: cloudName});

const MobileHeaderContainer = (props) => {
  const {children, WildToursList, BirdingToursList, TrekkingList} = props
  const [sidebarOpened, setIsSidebarOpened] = useState(false);

    return (
      <MediaQuery
        as={Sidebar.Pushable}
        maxWidth={1224}>
        <MobileHeader
          WildToursList={WildToursList}
          BirdingToursList={BirdingToursList}
          TrekkingList={TrekkingList}
          handleSidebarHide={() => setIsSidebarOpened(false)}
          sidebarOpened={sidebarOpened}
        />
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{padding: '1em 0em'}}
            vertical>
            <Menu inverted pointing secondary size='large'>
              <Menu.Item onClick={() => setIsSidebarOpened(prev => !prev)}>
                <Icon name='sidebar' />
              </Menu.Item>
              <Menu.Item header className='padding-unset primary' href='/'>
                <Image src={cloudinaryCore.url(`${publicIdRelativeUrl}/Logo_1`)} size='mini' centered bordered rounded style={{ marginRight: '1.5em' }} />
                WindTrails
              </Menu.Item>
            </Menu>
          </Segment>

          {children}
        </Sidebar.Pusher>
      </MediaQuery>
    )
}

export default MobileHeaderContainer;