import React, { useState, useEffect } from 'react'
import { Loader } from 'semantic-ui-react'
import { Carousel } from 'react-responsive-carousel'
import { Image, CloudinaryContext, Transformation } from 'cloudinary-react';
import { WindtrailsConstants, WindtrailsWebappFirebaseConfigs } from 'windtrails-components'
import MediaQuery from 'react-responsive'
import { collection, getDocs } from "firebase/firestore"

const { db, BANNER_IMAGES_REF } = WindtrailsWebappFirebaseConfigs;
const { cloudinaryConfig } = WindtrailsConstants
const { cloudName, publicIdRelativeUrl } = cloudinaryConfig

const MOBILE_IMAGE_HEIGHT = '250px'
const LARGE_SCREEN_IMAGE_HEIGHT = '250px'

const ResponsiveCarouselDisplay = ({device, imageHeight}) => {
  const [bannerImages, setBannerImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getBannerImages = async () => {
      const bannerImagesRef = await getDocs(collection(db, BANNER_IMAGES_REF));
      setLoading(false);
      let allbannerImages = []
      bannerImagesRef.forEach((doc) => {
        allbannerImages.push(doc.data().path);
      });
      setBannerImages(allbannerImages);
    }
    getBannerImages();
  }, []);

  const centerModeProps = device === 'large-device' ? {
    centerMode: true,
    centerSlidePercentage: '30'
  } : {}

  return (
      <Carousel
        autoPlay
        showThumbs={false}
        infiniteLoop
        {...centerModeProps}
        className={`home-page-banner background-transparent rounded-corners ${device}`}>
          {
            !loading ? 
            bannerImages.map((urlRef, index) => {
              const relImgRef=`${publicIdRelativeUrl}/${urlRef}`
              return (
                <div className='padding-1em' key={`${relImgRef}-${index}`}>
                  <CloudinaryContext cloudName={cloudName} width="60%" height={imageHeight}>
                    <Image publicId={relImgRef} >
                      <Transformation fetch_format="auto" />
                      <Transformation quality="auto" />
                      <Transformation  />
                    </Image>
                  </CloudinaryContext>
                </div>
              )
            }) : <Loader indeterminate size='big'>Loading upcoming events</Loader>
          }
      </Carousel>
    
  )
}

const Banner = () => {
  return (
    <React.Fragment>
      <MediaQuery maxWidth={1224} as={React.Fragment}>
        <ResponsiveCarouselDisplay device='mobile-device' imageHeight={MOBILE_IMAGE_HEIGHT} />
      </MediaQuery>
      <MediaQuery minWidth={1224} as={React.Fragment}>
        <ResponsiveCarouselDisplay device='large-device' imageHeight={LARGE_SCREEN_IMAGE_HEIGHT} />
      </MediaQuery>
    </React.Fragment>
  )
}

export default Banner
