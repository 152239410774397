import React, {useCallback, useState} from 'react'
import * as WindtrailsConstants from './constants';
import * as WindtrailsWebappFirebaseConfigs from './firebaseConfigs';
import {List, Segment, Header, Loader, Dimmer} from 'semantic-ui-react'
import {collection, getDocs } from "firebase/firestore"

const {HEADER_LABELS, TOURS_ICONS, ALL_ROUTES_MPA} = WindtrailsConstants
const {LISTING, EVENT_DETAILS} = ALL_ROUTES_MPA
const { db, BIRDING_LIST, WILD_TOURS_LIST } = WindtrailsWebappFirebaseConfigs;

const ViewAllList = ({selectedTour}) => {
  return (
    <List.Item
      className='padding-1em item-custom-hover'
      name={selectedTour}
      href={`${LISTING}?name=${selectedTour}`}
      target='_blank'
    >
      <List.Icon size='large' verticalAlign='middle'>
        <i className='fas fa-list margin-r-5'></i>
      </List.Icon>
      <List.Content>
        <List.Header>View all tours</List.Header>
      </List.Content>
    </List.Item>
  )
}

const selectedToursList = (ourTrips, selectedTour) => {
  const tripsList = ourTrips[selectedTour].map(({name, displayName}) => {
    return (
      <List.Item
        key={name}
        name={name}
        className='padding-1em item-custom-hover'
        href={`${EVENT_DETAILS}?eventid=${name}`}
        target='_blank'
      >
        <List.Icon size='large' verticalAlign='middle'>
          <i className={`${TOURS_ICONS[selectedTour]} margin-r-5`}></i>
        </List.Icon>
        <List.Content>
          <List.Header>{displayName}</List.Header>
        </List.Content>
      </List.Item>
    )
  })
  return (
    <List divided relaxed size='medium'>
      {' '}
      {tripsList.length ? tripsList : null}
      <ViewAllList selectedTour={selectedTour} />
    </List>
  )
}

const WhatDoWeDo = () => {
  const [selectedTour, setSelectedTour] = useState('')
  const [ourTrips, setOurTrips] = useState({})
  const [isFetchingList, seIisFetchingList] = useState(false)

  const handleTourSelect = useCallback(
    (event, element, apiName) => {
      const {name: elementName} = element
      setSelectedTour(elementName);
      if (!ourTrips[elementName]) {
        const getSelectedCategoryInfo = async () => {
          seIisFetchingList(true);
          const taskColRef = await getDocs(collection(db, apiName))
          let cateogryTours = []
          taskColRef.forEach((doc) => {
            cateogryTours.push(doc.data());
          });
          seIisFetchingList(false);
          const tripsCopy = {...ourTrips}
          tripsCopy[elementName] = cateogryTours
          setOurTrips(tripsCopy)
        }
        getSelectedCategoryInfo();
      }
    },
    [setOurTrips, setSelectedTour, ourTrips],
  )

  return (
    <React.Fragment>
      <Segment color='orange'>
        <List className='tours-major-category' divided relaxed size='large'>
          <List.Item
            name='BirdingToursList'
            className='padding-1em item-custom-hover'
            onClick={(event, element) => handleTourSelect(event, element, BIRDING_LIST)}>
            <List.Icon name='find' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header>Birding Tours</List.Header>
              Traveling to Birding hotspots of India, Workshops and spotting
              migratory birds
            </List.Content>
          </List.Item>
          <List.Item
            name='WildToursList'
            className='padding-1em item-custom-hover'
            onClick={(event, element) => handleTourSelect(event, element, WILD_TOURS_LIST)}>
            <List.Icon name='paw' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header>Wild Tours</List.Header>
              Wild Tours and photo tours to Protected National Parks of India
            </List.Content>
          </List.Item>
          <List.Item
            name='TrekkingList'
            className='padding-1em item-custom-hover'
            // onClick={(event, element) => handleTourSelect(event, element)}
          >
            <List.Icon name='quidditch' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header>Treks</List.Header>
              Trekking Trails and hikes (On Demand)
            </List.Content>
          </List.Item>
        </List>
        { isFetchingList ? (
          <Dimmer active>
            <Loader size='large'>Loading</Loader>
          </Dimmer>
        ) : null }

      </Segment>

      {ourTrips[selectedTour] && (
        <Segment color='yellow'>
          <Header> {HEADER_LABELS[selectedTour]} </Header>
          {selectedToursList(ourTrips, selectedTour)}
        </Segment>
      )}
    </React.Fragment>
  )
}

export default WhatDoWeDo
