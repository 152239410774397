import React from 'react'
import ReactGA from 'react-ga4'

/*Components*/
import {WindTrailsFooter, ResponsiveContainer, analyticsTrackingId} from 'windtrails-components'
import DesktopHomeContainer from './DesktopHomeContainer'
import MobileHomeContainer from './MobileHomeContainer'
import MediaQuery from 'react-responsive'

ReactGA.initialize(analyticsTrackingId);

const windHome = () => {
  // location is an object like window.location
  ReactGA.set({page: window.location.pathname}) // Update the user's current page
  return (
    <div className='main-body home-page-container'>
      <ResponsiveContainer>
        <MediaQuery maxWidth={1224}as={React.Fragment}>
          <MobileHomeContainer />
        </MediaQuery>
        <MediaQuery minWidth={1224} as={React.Fragment}>
          <DesktopHomeContainer />
        </MediaQuery>
      </ResponsiveContainer>
      <WindTrailsFooter />
    </div>
  )
}

export default windHome
