// const TrekkingList = [
//   {
//     name: 'bramhagiri',
//     displayName: 'Bramhagiri Wild Life Sanctury',
//   },
//   {
//     name: 'kudremukha',
//     displayName: 'Kudremukha National Park',
//   },
//   {
//     name: 'ankola_beach_trek',
//     displayName: 'Remote Beach Treks',
//   },
//   {
//     name: 'bangalore_treks',
//     displayName: 'Bangalore Treks',
//   },
// ]

const TrekkingList = []

export default TrekkingList
