import React from 'react'
import Banner from './Banner'
import OtherMajorEvents from './OtherMajorEvents'
import UpcomingEventsSlider from './UpcomingEventsSlider'
import {CustomCard} from 'windtrails-components'
import {Grid} from 'semantic-ui-react'
import AboutUsDetail from './AboutUsDetail'

const DesktopHomeContainer = () => {
  return (
    <div className='main-page margin-1em'>
      <div className='content-main'>
        <Grid>
          <Grid.Row className='margin-t-30 margin-b-30 padding-top-unset'>
            <Banner />
          </Grid.Row>

          <Grid.Row className='margin-b-30 other-major-events-container'>
            <UpcomingEventsSlider />
          </Grid.Row>
          
          <Grid.Row className='margin-b-30 other-major-events-container'>
            <Grid.Column 
              computer={4}
              mobile={16}
              className='upcoming-tours-card-container display-flex-row align-items-center'>
                <CustomCard
                  imgUrl={'tours_card'}
                  infoTitle={'Wild Expeditions'}
                  infoDesc={'Tours and Workshops'}
                  cardDesc={'what we do!'}
                  cardType={'whatDoWeDo'}
                  customClass={'width-full rounded-corners'}
                  AboutUsDetail={AboutUsDetail}
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={12} largeScreen={12}>
                <OtherMajorEvents />
              </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default DesktopHomeContainer
