import React from 'react'
import {Button, Header} from 'semantic-ui-react'
import {Image, CloudinaryContext, Transformation} from 'cloudinary-react';
import {cloudinaryConfig} from './constants'
const {cloudName, publicIdRelativeUrl} = cloudinaryConfig

const CustomCard = props => {
  const {imgUrl, infoTitle, infoDesc, cardType, customClass = '', AboutUsDetail} = props
  return (
    <div className={`width-full box card-top-band card ${customClass} display-flex-column justify-content-space-bet`}>
      <div className='box-icon'>
        <CloudinaryContext cloudName={cloudName} width="100%" height="220">
          <Image publicId={`${publicIdRelativeUrl}/${imgUrl}`} >
            <Transformation fetch_format="auto" />
            <Transformation quality="auto" />
            <Transformation  />
          </Image>
        </CloudinaryContext>
      </div>
      <div className='info'>
        <div className='about_box_title'>
          <Header as='h3' className='primary-font'>
            {infoTitle}
            <Header.Subheader className='secondary-font'>
              {infoDesc}
            </Header.Subheader>
          </Header>
        </div>
      </div>
      <div className='line'></div>
      <AboutUsDetail
        trigger={
          <div className='width-full display-flex-row justify-content-center'>
            <Button size='medium' className='margin-t-b-10 width-60-per windtrails-button'>
              Read more
            </Button>
          </div>
        }
        aboutUsSelection={cardType}
      />
    </div>
  )
}

export default CustomCard
