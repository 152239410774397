import React from 'react'
import {Menu, Sidebar} from 'semantic-ui-react'
import {ALL_ROUTES_MPA} from './constants'
const {CONTACT_US, ABOUT_US, GALLERY, GENERAL_FAQS, EVENT_DETAILS, LISTING} = ALL_ROUTES_MPA

const MobileHeader = (props) => {
  const {WildToursList, BirdingToursList, TrekkingList, sidebarOpened, handleSidebarHide} = props
  return (
    <Sidebar
      as={Menu}
      animation='push'
      inverted
      onHide={handleSidebarHide}
      vertical
      visible={sidebarOpened}>
      <Menu.Item as='a' href='/'>
        Home
      </Menu.Item>
      <Menu.Item className='primary-font' simple item>
        Wild Tours
        <Menu.Menu>
          {WildToursList.map(({name, displayName}) => {
            return (
              <Menu.Item
                href={`${EVENT_DETAILS}?eventid=${name}`}
                target='_blank'
                key={name}
                icon={<i className="fas fa-paw margin-r-5"></i>}
                text={displayName}
                name={name}>
              </Menu.Item>
            )
          })}
          <Menu.Item name='WildToursList' 
            href={`${LISTING}?name=WildToursList`}
            target='_blank'>
            <i className="fas fa-list margin-r-5"></i>
            View all tours
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item
        name='BirdingToursList'
        className='primary-font'
        simple
        item
      >
        Birding Tours
        <Menu.Menu>
          {BirdingToursList.map(({name, displayName}) => {
            return (
              <Menu.Item
                href={`${EVENT_DETAILS}?eventid=${name}`}
                target='_blank'
                icon={<i className="fas fa-feather-alt margin-r-5"></i>}
                key={name}
                text={displayName}
                name={name}>
              </Menu.Item>
            )
          })}
          <Menu.Item 
            name='BirdingToursList' 
            href={`${LISTING}?name=BirdingToursList`}
            target='_blank'
          >
            <i className="fas fa-list margin-r-5"></i>
            View all tours
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item className='primary-font' as='a' href={`${LISTING}?name=EducationalToursList`} target='_blank'>
        Educational Tours
      </Menu.Item>
      <Menu.Item className='primary-font' as='a' href={`${LISTING}?name=TrekkingList`} target='_blank'> 
        Trekking Trails
      </Menu.Item>
      <Menu.Item className='primary-font' as='a' href={GALLERY}>
        Gallery
      </Menu.Item>
      <Menu.Item className='primary-font' as='a' href={ABOUT_US}>
        About Us
      </Menu.Item>
      <Menu.Item className='primary-font' as='a' href={CONTACT_US}>
        Contact Us
      </Menu.Item>
    </Sidebar>
  )
}

export default MobileHeader
