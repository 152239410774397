import moment from 'moment'
import {ALL_ROUTES_MPA} from './constants'
const {EVENT_DETAILS} = ALL_ROUTES_MPA

// export const onEventSelect = (eventName, {fromDate, toDate} = {}) => {
//   const appendUrlParams = (fromDate && toDate) ? `?fromDate=${fromDate}&toDate=${toDate}`: '';
//   window.open(`#/windTrailsEventDetails/${eventName}${appendUrlParams}`, '_blank')
// }

export const onEventSelect = (eventName, {fromDate, toDate} = {}) => {
  const appendUrlParams = (fromDate && toDate) ? `&fromDate=${fromDate}&toDate=${toDate}`: '';
  window.open(`${EVENT_DETAILS}?eventid=${eventName}${appendUrlParams}`, '_blank')
}


export const handleIDLinkRoute = ({history, routeLink, id}) =>
  history.push(`${routeLink}/${id}`)

export const handleSimpleLinkRoute = ({history, routeLink, newTab}) =>
  newTab ? window.open(routeLink, '_blank') : history.push(`${routeLink}`)

export const handleAnchorRoute = ({history, routeLink, newTab}) =>
  newTab ? window.open(`#${routeLink}`, '_blank') : history.push(`${routeLink}`)

export const getDispDate = ({fromDate, toDate}) => {
  const fromMomentObj = moment(fromDate, 'DD-MM-YYYY')
  const toMomentObj = moment(toDate, 'DD-MM-YYYY')
  const fromMonth = fromMomentObj.format("MMMM");
  const toMonth = toMomentObj.format("MMMM");
  const year = fromMomentObj.format("YYYY");
  const from = fromMomentObj.format("Do");
  const to = toMomentObj.format("Do");
  const shldDispToMonth = fromMonth !== toMonth

  if (fromMomentObj.isValid() && toMomentObj.isValid()) {
    return `${fromMonth} ${from} - ${shldDispToMonth ? toMonth : ''} ${to}, ${year}`
  } else {
    return 'TBD | On-Request | Open'
  }
  
}

export const useQuery = () => {
  return new URLSearchParams(window.location.search);
}
