import React from 'react'
import {Image, CloudinaryContext, Transformation} from 'cloudinary-react';
import {Header} from 'semantic-ui-react'
import {WindtrailsConstants, WindtrailsUtils} from 'windtrails-components'

const {getDispDate, onEventSelect} = WindtrailsUtils
const {EXTERNAL_LINKS, cloudinaryConfig} = WindtrailsConstants
const {cloudName} = cloudinaryConfig
const {fb_page, insta_page} = EXTERNAL_LINKS

const EventCard = ({name, locationName, shortInfo, state, fromDate, 
  toDate, imgUrl, location, additionalClasses}) => {
  const eventClickHandler = () => onEventSelect(name, {fromDate, toDate})
  return (
    <div
      name={name}
      className={`box card-top-band upcoming-tours-card rounded-corners ${additionalClasses}`}
      onClick={eventClickHandler}>
      <div className='box-icon'>
        <CloudinaryContext cloudName={cloudName}>
          <Image publicId={imgUrl} width="100%" height="210px">
            <Transformation fetch_format="auto" />
            <Transformation quality="auto" />
          </Image>
        </CloudinaryContext>
      </div>
      <div className='info float-container display-flex-column justify-content-space-bet'>
        <div className='card-title'>
          <Header as='h3' className='primary-font'>
            {locationName}
            <Header.Subheader>
              {getDispDate({fromDate, toDate})}
            </Header.Subheader>
          </Header>
        </div>
        <div className='upcoming-tour-shortinfo'>
          <p> {shortInfo} </p>
          <hr className='hr-class' />
        </div>
        <div className='display-flex-row justify-content-space-bet location-main'>
          <div className='location'>
            <a
              target='_blank'
              href={location}>
              <i className='fa fa-map-marker font-size-1-5'></i>
            </a>
            <span>{`${locationName}, ${state}`}</span>
          </div>
          <div className='user-icons'>
            <a href={insta_page}> <i className='fab fa-instagram font-size-1-5 secondary-font'></i> </a>
            <a
              target='_blank'
              href={fb_page}>
              <i className='fab fa-facebook font-size-1-5 secondary-font'></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    )
}

export default EventCard;
