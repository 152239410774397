import React, { useEffect } from 'react'
import DesktopHeaderContainer from './DesktopHeaderContainer'
import MobileHeaderContainer from './MobileHeaderContainer'
import WildToursList from './static/data/WildToursList'
import BirdingToursList from './static/data/BirdingToursList'
import TrekkingList from './static/data/TrekkingList'

const ResponsiveContainer = ({children}) => {
  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <DesktopHeaderContainer
        WildToursList={WildToursList}
        BirdingToursList={BirdingToursList}
        TrekkingList={TrekkingList}
      >
        {children}
      </DesktopHeaderContainer>
      <MobileHeaderContainer
        WildToursList={WildToursList}
        BirdingToursList={BirdingToursList}
        TrekkingList={TrekkingList}
      >
        {children}
      </MobileHeaderContainer>
    </React.Fragment>
  )
}

export default ResponsiveContainer
