import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './static/styles/home.scss'
import './static/styles/homepage.scss'
import 'semantic-ui-css/semantic.min.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

serviceWorker.unregister();
