import React, { useState } from 'react'
import {Segment, Visibility} from 'semantic-ui-react'
import DesktopHeader from './DesktopHeader'
import MediaQuery from 'react-responsive'

const DesktopHeaderContainer = (props) => {
  const [fixed, setIsFixed] = useState(false);
  const {children, WildToursList, BirdingToursList, TrekkingList} = props;

  return (
    <MediaQuery minWidth={1224}>
      <Visibility
        once={false}
        onBottomPassed={() => setIsFixed(prev => !prev)}
        onBottomPassedReverse={() => setIsFixed(prev => !prev)}>
        <Segment
          className={''}
          inverted
          textAlign='center'
          style={{padding: '0em 0em'}}
          vertical>
          <DesktopHeader
            fixed={fixed}
            WildToursList={WildToursList}
            BirdingToursList={BirdingToursList}
            TrekkingList={TrekkingList}
          />
        </Segment>
      </Visibility>

      {children}
    </MediaQuery>
  )
  
}

export default DesktopHeaderContainer;