const BirdingToursList = [
  {
    name: 'pelagic',
    displayName: 'Mangalore Pelagic Birding',
    price: 7500,
    location: 'https://goo.gl/maps/8SZDcg1eRXuvosgcA',
    highlights: '2 Birding tours, 2 Days/1 Nights',
    state: 'KA',
    imgUrl: 'pelagic/pelagic_card-img'
  },
  {
    name: 'hampi',
    displayName: 'Hampi Birding',
    price: 8000,
    location: 'https://goo.gl/maps/niuLaQGzyaXhmm2j8',
    highlights: '5 Birding spots, 3 Days/2 Nights',
    state: 'KA',
    imgUrl: 'hampi/hampi_event_card'
  },
  // {
  //   name: 'brhills',
  //   displayName: 'BR Hills',
  //   price: 17000,
  //   location: 'https://goo.gl/maps/8SZDcg1eRXuvosgcA',
  //   highlights: '5 wild safaris',
  //   state: 'MH',
  //   imgUrl: 'pench/pench_card'
  // },
  // {
  //   name: 'bhg',
  //   displayName: 'Bar-Headed Geese Migration',
  //   price: 17000,
  //   location: 'https://goo.gl/maps/8SZDcg1eRXuvosgcA',
  //   highlights: '5 wild safaris',
  //   state: 'MH',
  //   imgUrl: 'pench/pench_card'
  // },
]

export default BirdingToursList
