import ResponsiveContainer from './ResponsiveContainer'
import WindTrailsFooter from './Footer'
import ImageDisplayModal from './ImageDisplayModal'
import ImageCredits from './ImageCredits'
import CustomCard from './CustomCard'
import WhatDoWeDo from './WhatDoWeDo';
import * as WindtrailsConstants from './constants'
import * as WindtrailsUtils from './utils'
import * as WindtrailsWebappFirebaseConfigs from './firebaseConfigs';

import './static/styles/components.scss'
import './static/styles/common-classes.scss'

const analyticsTrackingId = 'G-B6G2Q5CX6M';

export {
  ResponsiveContainer,
  WindTrailsFooter,
  ImageDisplayModal,
  ImageCredits,
  CustomCard,
  WhatDoWeDo,
  WindtrailsConstants,
  WindtrailsUtils,
  WindtrailsWebappFirebaseConfigs,
  analyticsTrackingId
}